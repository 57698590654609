import {
  type AnalyticsBrowser,
  type EventProperties,
} from '@segment/analytics-next';
import { useMemo } from 'react';

import { useMyOrgId } from '../components/Organization/hooks/organization';
import { useAnalytics } from './AnalyticsContext';

class OrgAnalytics {
  constructor(
    private readonly analytics: AnalyticsBrowser,
    readonly orgId: string | null
  ) {}

  trackUpgradeModalViewed() {
    this.analytics.track('Org Upgrade Modal Viewed');
  }

  trackUpgradeModalDismissed(): void {
    this.analytics.track('Org Upgrade Modal Dismissed');
  }

  trackUpgradeButtonClicked(props: EventProperties) {
    this.analytics.track(
      'Org Upgrade Button Clicked',
      this.buildEventProps(props)
    );
  }

  trackSubscriptionPaymentSubmitted(props: EventProperties) {
    this.analytics.track(
      'Subscription Payment Form Submitted',
      this.buildEventProps(props)
    );
  }

  trackSubscriptionPaymentFailed(props: EventProperties) {
    this.analytics.track(
      'Subscription Payment Failed',
      this.buildEventProps(props)
    );
  }

  trackSubscriptionCancelReasonSubmitted(props: EventProperties) {
    this.analytics.track(
      'Subscription Cancel Reason Submitted',
      this.buildEventProps(props)
    );
  }

  trackSubscriptionCancelFirstOfferRejected(props?: EventProperties) {
    this.analytics.track(
      'Subscription Cancel First Offer Rejected',
      this.buildEventProps(props)
    );
  }

  trackSubscriptionCancelFirstOfferAccepted(props?: EventProperties) {
    this.analytics.track(
      'Subscription Cancel First Offer Accepted',
      this.buildEventProps(props)
    );
  }

  trackSubscriptionCancelFinalOfferRejected(props?: EventProperties) {
    this.analytics.track(
      'Subscription Cancel Final Offer Rejected',
      this.buildEventProps(props)
    );
  }

  trackSubscriptionCancelFinalOfferAccepted(props?: EventProperties) {
    this.analytics.track(
      'Subscription Cancel Final Offer Accepted',
      this.buildEventProps(props)
    );
  }

  trackSubscriptionCancelOfferAcceptedCallSkipped(props?: EventProperties) {
    this.analytics.track(
      'Subscription Cancel Offer Accepted Call Skipped',
      this.buildEventProps(props)
    );
  }

  trackSubscriptionCancelOfferAcceptedCallScheduled(props?: EventProperties) {
    this.analytics.track(
      'Subscription Cancel Offer Accepted Call Scheduled',
      this.buildEventProps(props)
    );
  }

  trackSubscriptionCancelSupportCallSkipped(props?: EventProperties) {
    this.analytics.track(
      'Subscription Cancel Support Call Skipped',
      this.buildEventProps(props)
    );
  }

  trackSubscriptionCancelSupportCallScheduled(props?: EventProperties) {
    this.analytics.track(
      'Subscription Cancel Support Call Scheduled',
      this.buildEventProps(props)
    );
  }

  trackSubscriptionCancelConfirmed(props?: EventProperties) {
    this.analytics.track(
      'Subscription Cancel Confirmed',
      this.buildEventProps(props)
    );
  }

  private buildEventProps(props?: EventProperties): EventProperties {
    return {
      ...props,
      orgId: this.orgId,
    };
  }
}

export function useOrgAnalytics(): OrgAnalytics {
  const analytics = useAnalytics();
  const orgId = useMyOrgId();

  return useMemo(() => new OrgAnalytics(analytics, orgId), [analytics, orgId]);
}
